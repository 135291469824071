// import React from "react";
// import "bootstrap/dist/css/bootstrap.min.css";

// const WeeklyTests = () => {
//   return (
//     <div className="container mt-4">
//         <div className="row">
//             <div className="col-lg-12">
//                 <h2 className="text-center">Weekly Tests</h2>
//             </div>
//         </div>
        
//             <div className="table-responsive">
//         <table className="table table-bordered table-striped mt-3">
//           <thead className="thead-dark">
//             <tr>
//               <th>#</th>
//               <th>Test Name</th>
//               <th>Exam Date</th>
//               <th>Status</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
           
//               <tr>
//                 <td>1</td>
//                 <td>PSC Geomatics Week 1 Exam</td>
//                 <td>2024-11-30</td>
//                 <td>Active</td>
//                 <td>
//                 <div class="btn-group" role="group" aria-label="Basic example">
//                     <button type="button" class="btn btn-success btn-sm">Take Exam</button>
//                     <button type="button" class="btn btn-success btn-sm">View Results</button>
//                     <button type="button" class="btn btn-success btn-sm">Instructions</button>
//                 </div>
//                 </td>
//               </tr>
//           </tbody>
//         </table>
//       </div>
//             </div>
   
//   );
// };



// export default WeeklyTests;



import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
// import WeeklyExam from "./exams/WeeklyExam";


const WeeklyTests = () => {
  const [tests, setTests] = useState([]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [id, setId] = useState([]);
  // const location = useLocation();
  const history = useHistory();
  const timeOffsetHours = 5.75;
  const [testname,setTestname] = useState([]);
  const [testdate,setTestdate] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    axios
      .get("https://nec.geoneer.com.np/api/exam/active-weekly-tests/SO-PSC/")
      .then((response) => {
        setTests(response.data); // Store the fetched data in the state
        setIsLoaded(true); // Mark the data as loaded
      })
      .catch((error) => {
        setError(error.message); // Handle errors
        setIsLoaded(true);
      });
  }, []);



  const handleWeeklyExam= (id,testname) => {
    setId(id);
    setTestname(testname);
    

    // Use history.push to navigate to the '/chapterwise-exam' route with props
    history.push({
      pathname: '/weekly-exam',
     
      state: {
        id: id,
        // chapterCode: chapterCode,
        testname:testname,
        // Add other props as needed
      },
    });
  };

  const handleEveryWeeklyExam= (id,testname,testdate) => {
    setId(id);
    setTestname(testname);
    setTestdate(testdate);

    // Use history.push to navigate to the '/chapterwise-exam' route with props
    history.push({
      pathname: '/every-weekly-test-result',
     
      state: {
        id: id,
        // chapterCode: chapterCode,
        testname:testname,
        testdate:testdate,
        // Add other props as needed
      },
    });
  };




  const isCurrentTimeWithinRange = (testDate, startTime, endTime) => {
    const currentTime = new Date(); // Local current time
    const serverTime = new Date(currentTime.getTime() - timeOffsetHours * 60 * 60 * 1000); // Adjust for server time

    // Combine testDate with startTime and endTime to get valid test start and end times
    const testStart = new Date(`${testDate}T${startTime}`); // Full start time
    let testEnd = new Date(`${testDate}T${endTime}`); // Full end time

    // Handle case where the end time is the next day (i.e., after midnight)
    if (testEnd <= testStart) {
      testEnd.setDate(testEnd.getDate() + 1); // Move end time to the next day
    }

    return serverTime >= testStart && serverTime <= testEnd; // Check if current time is within range
  };




  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-lg-12">
          <h2 className="text-center">Weekly Tests</h2>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-striped mt-3">
          <thead className="thead-dark">
            <tr>
              <th>#</th>
              <th>Test Name</th>
              <th>Exam Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {error && (
              <tr>
                <td colSpan="5" className="text-danger text-center">
                  Error: {error}
                </td>
              </tr>
            )}

            {!isLoaded && !error && (
              <tr>
                <td colSpan="5" className="text-center">
                  Loading...
                </td>
              </tr>
            )}

            {isLoaded && tests.length === 0 && !error && (
              <tr>
                <td colSpan="5" className="text-center">
                  No active tests available.
                </td>
              </tr>
            )}

            {isLoaded &&
              tests.map((test, index) => (
                <tr key={test.id}>
                  <td>{index + 1}</td>
                  <td>{test.test_name}</td>
                  <td>{test.test_date}</td>
                  <td>{test.test_start_time}</td>
                  <td>{test.test_end_time}</td>
                  <td>{test.active_status ? "Active" : "Inactive"}</td>
                  <td>
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                    {isCurrentTimeWithinRange(
                      test.test_date,
                      test.test_start_time,
                      test.test_end_time
                    ) && (
                      <Button
                        variant="success"
                        onClick={() => handleWeeklyExam(test.id, test.test_name)}
                      >
                      
                        {console.log(id)}
                        {console.log(testname)}
                        Take Exam
                      </Button>
                    )}

{/* <Button
                        variant="success"
                        onClick={() => handleWeeklyExam(test.id, test.test_name)}
                      >
                      
                        {console.log(id)}
                        {console.log(testname)}
                        Take Exam
                      </Button> */}
                      {/* <button type="button" className="btn btn-success btn-sm">
                        View Results
                      </button> */}


                    <Button className="btn btn-success btn-sm"
                        variant="success" 
                        onClick={() => handleEveryWeeklyExam(test.id, test.test_name,test.test_date)}
                      >
                      
                        {console.log(id)}
                        {console.log(testname)}
                        {console.log(testdate)}
                        View Results
                      </Button>
                      <button type="button" className="btn btn-success btn-sm">
                        Instructions
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>


      <div>
      {/* {id !== null && (
        <WeeklyExam id={id} />
      )} */}
    </div>

    </div>
  );
};

export default WeeklyTests;

